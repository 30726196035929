import type { MintItem, MintedItem } from '~/service/modules/stats'

interface AirdropInfo {
  interactionScore: number
  referralScore: number
  totalScore: 100
  userLoyaltyLevel: number
}

interface InteractionStatus {
  id: number
  type: string
  completed: boolean
  score: number
  dependencyId: number
  groupId: number
  groupOrder: number
}

interface Referral {
  code: string
  returnScore: number
  userAddress: string
  invitationCount: number
}

interface Loyalty {
  changeAt: string
  changeNumber: number
  changeType: string
  description: string
  levelAfter: number
  title: string
  userAddress: string
}

interface CodeAllocation {
  codes: number
  threshold: number
}

export const useAirdrop = createGlobalState(() => {
  const airdropInfo = ref<AirdropInfo>()
  const interactionStatus = ref<InteractionStatus[]>([])
  const loyaltyList = ref<Loyalty[]>([])
  const referralList = ref<Referral[]>([])
  const codeAllocation = ref<CodeAllocation[]>([])

  const { pause, resume, isActive } = useIntervalFn(() => {
    vesselApi.stats.getAirdropInfo().then((res) => {
      airdropInfo.value = res.data
      interactionStatus.value = res.data.tasks
    })
  }, 20000, {
    immediate: false,
  })

  function initAirdrop() {
    if (IS_MAINNET) {
      return
    }
    vesselApi.stats.getAirdropInfo().then((res) => {
      airdropInfo.value = res.data
      interactionStatus.value = res.data.tasks
    })
    vesselApi.stats.getAirdropLoyaltyHistory().then((res) => {
      // console.log('loyalty history', res.data)
      loyaltyList.value = res.data.changes
    }).catch(() => {
      loyaltyList.value = []
    })
    vesselApi.stats.getAirdropReferralMember().then((res) => {
      referralList.value = res.data.referralDetails
    }).catch(() => {
      referralList.value = []
    })
    vesselApi.stats.getCodeAllocation().then((res) => {
      codeAllocation.value = res.data.codeAllocation
    })
  }

  return {
    initAirdrop,
    airdropInfo,
    interactionStatus,
    referralList,
    pause,
    resume,
    loyaltyList,
    codeAllocation,
  }
})
