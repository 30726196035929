<script setup lang="tsx">
import type { Contract } from 'web3'
import { useRouteQuery } from '@vueuse/router'
import type { SelectOption } from 'naive-ui'
import VIcon from '@/components/v/Icon.vue'

const emit = defineEmits(['submit'])

const { modal, closeModal } = useModal()

const route = useRoute()
const { web3, checkChain } = useConnector()
const { address } = useUser()
const { vesselKey } = useKey()
const { currencyList } = useSymbol()
const router = useRouter()
const assetId = useRouteQuery('assetId')
const symbol = useRouteQuery('symbol')

const notification = useNotification()

const registeredAddress = useStorage('REGISTERED_ADDRESS', [])
const current = ref()
const balance = ref('-')
const approveBalance = ref('')
const amount = ref('')
const errorMsg = ref('')
const isLoadingBalance = ref(false)
const isDeposit = ref(false)

let contract: Contract<any>

const currentItem = computed(() => currencyList.value.find(i => i.assetId === current.value))
const isRegistered = computed(() => registeredAddress.value.includes(address.value))

function toMaxAmount() {
  if (balance.value !== '-') {
    if (current.value === 0) {
      amount.value = `${Math.max(0, +balance.value - 0.01)}`
      const b = `${+balance.value - 0.01}`
      const limit = currentItem.value.depositLimit
      const precision = currentItem.value.depositPrecision
      amount.value = `${b.split('.')[0].slice(-1 * limit)}${b.includes('.') ? '.' : ''}${!b.includes('.') ? '' : b.split('.')?.[1]?.slice(0, precision)}`
    }
    else {
      const limit = currentItem.value.depositLimit
      const precision = currentItem.value.depositPrecision
      amount.value = `${balance.value.split('.')[0].slice(-1 * limit)}${balance.value.includes('.') ? '.' : ''}${!balance.value.includes('.') ? '' : balance.value.split('.')?.[1]?.slice(0, precision)}`
    }
  }
}

async function getBalance(currency: number) {
  if (!currentItem.value || !web3.value) {
    balance.value = '-'
    return
  }
  isLoadingBalance.value = true
  if (currency === 0) {
    await web3.value.eth.getBalance(address.value).then((res) => {
      balance.value = dividedByPower(res, currentItem.value.onChainDecimal)
    }).finally(() => {
      isLoadingBalance.value = false
    })
  }
  else {
    await (contract.methods.balanceOf as any)(address.value).call({}, 'latest').then((res: any) => {
      balance.value = dividedByPower(res as any, currentItem.value.onChainDecimal)
    }).finally(() => {
      isLoadingBalance.value = false
    }).catch((res) => {
      console.log(res.data, new Date().getTime())
    })
  }
}
async function getApproveBalance(currency: number) {
  if (currency === 0) {
    approveBalance.value = balance.value
  }
  else {
    await (contract.methods.allowance as any)(address.value, VAULT_ADDRESS).call().then((res: any) => {
      approveBalance.value = dividedByPower(res, currentItem.value.onChainDecimal)
    })
  }
}

async function handleChangeCurrency(currency: number) {
  amount.value = ''
  const currencyItem = currencyList.value.find(i => i.assetId === currency)
  if (currency !== 0) {
    contract = await getContract(currencyItem.address)
    if (!contract || !address.value) {
      return
    }
  }
  await getBalance(currency)
  getApproveBalance(currency)
}

async function getAdminSignature() {
  const res = await vesselApi.user.getAdminSignature()
  const sig = res.data.signature
  return {
    operator: res.data.operator,
    r: sig.slice(0, 66),
    s: `0x${sig.slice(66, 130)}`,
    v: web3.value.utils.hexToNumber(`0x${sig.slice(130, 132)}`),
  }
}

async function notifySuccess(asset: string) {
  notification.success({
    title: 'Deposit Successful',
    content: `Your deposit of ${asset} has been successfully processed.`,
    duration: 2000,
  })
  await getBalance(current.value)
  getApproveBalance(current.value)
}

async function notifyError(asset: string) {
  notification.success({
    title: 'Deposit Error',
    content: `Your deposit of ${asset} has been failed.`,
    duration: 2000,
  })
  await getBalance(current.value)
  getApproveBalance(current.value)
}

async function notifyWaiting(asset: string) {
  notification.success({
    title: 'Deposit Committed',
    content: `Please wait for the blockchain confirmation for your deposit of ${asset}.`,
    duration: 2000,
  })
  await getBalance(current.value)
  getApproveBalance(current.value)
  isDeposit.value = false
  amount.value = ''
  closeModal('deposit')
}

async function deposit() {
  const isRightChain = await checkChain()
  if (!isRightChain) {
    return
  }
  if (isDeposit.value || +amount.value > +balance.value || !+amount.value) {
    return
  }
  let reg: RegExp
  if (currentItem.value.depositPrecision) {
    reg = new RegExp(`^\\d{1,${currentItem.value.depositLimit}}(\\.\\d{1,${currentItem.value.depositPrecision}})?$`)
  }
  else {
    reg = new RegExp(`^\\d{1,${currentItem.value.depositLimit}}$`)
  }
  if (!reg.test(amount.value)) {
    errorMsg.value = `Deposit amount must be < ${10 ** currentItem.value.depositLimit} with < ${currentItem.value.depositPrecision} decimals.`
    return
  }
  try {
    const _amount = amount.value
    isDeposit.value = true
    const depositAmount = multiplyByPower(amount.value, currentItem.value.onChainDecimal)
    const vaultContract = await getContract(VAULT_ADDRESS)
    const innerPublicKey = hexToByte(vesselKey.value.publicKey.slice(2))

    if (current.value !== 0) {
      // not enough

      if (+approveBalance.value < +_amount) {
        await (contract.methods.approve as any)(VAULT_ADDRESS, depositAmount).send({
          maxPriorityFeePerGas: web3.value.utils.toBigInt(web3.value.utils.toWei('0.01', 'gwei')),
        })
        await getApproveBalance(current.value)
      }
      if (!isRegistered.value) {
        const { v, r, s, operator } = await getAdminSignature()
        await (vaultContract.methods.registerAndDepositERC20 as any)(
          innerPublicKey,
          operator,
          v,
          r,
          s,
          currentItem.value.assetId,
          depositAmount,
        ).send({
          maxPriorityFeePerGas: web3.value.utils.toBigInt(web3.value.utils.toWei('0.01', 'gwei')),
        }).on('transactionHash', () => {
          notifyWaiting(`${_amount} ${currentItem.value.assetName}`)
        }).on('error', () => {
          notifyError(`${_amount} ${currentItem.value.assetName}`)
        })
      }
      else {
        await (vaultContract.methods.depositERC20 as any)(
          currentItem.value.assetId,
          depositAmount,
          innerPublicKey,
        ).send({
          maxPriorityFeePerGas: web3.value.utils.toBigInt(web3.value.utils.toWei('0.01', 'gwei')),
        }).on('transactionHash', () => {
          notifyWaiting(`${_amount} ${currentItem.value.assetName}`)
        })
      }
    }
    else {
      // ETH
      if (!isRegistered.value) {
        const { v, r, s, operator } = await getAdminSignature()
        await (vaultContract.methods.registerAndDepositNative as any)(
          innerPublicKey,
          operator,
          v,
          r,
          s,
        )
          .send({
            value: depositAmount,
            maxPriorityFeePerGas: web3.value.utils.toBigInt(web3.value.utils.toWei('0.01', 'gwei')),
          }).on('transactionHash', () => {
            notifyWaiting(`${_amount} ${currentItem.value.assetName}`)
          })
      }
      else {
        await (vaultContract.methods.depositNative as any)(innerPublicKey)
          .send({
            maxPriorityFeePerGas: web3.value.utils.toBigInt(web3.value.utils.toWei('0.01', 'gwei')),
            value: depositAmount,
          }).on('transactionHash', () => {
            notifyWaiting(`${_amount} ${currentItem.value.assetName}`)
          })
      }
    }
    registeredAddress.value.push(address.value)
  }
  catch (e: any) {
    console.log(e)

    isDeposit.value = false
  }
}

function toFaucet() {
  router.push(`/faucet?token=${currentItem.value.address}`)
  closeModal('deposit')
}

whenever(() => modal.value.deposit, () => {
  checkChain()
  if (assetId.value) {
    current.value = +assetId.value
  }
  else if (symbol.value) {
    const pairs = getCurrencyFromSymbol(symbol.value)
    current.value = +pairs.baseId
  }
  else if (!current.value) {
    current.value = currencyList.value.filter(i => i.canDepositFrontend)[0]?.assetId
  }
  handleChangeCurrency(current.value)
})

function renderLabel(option: SelectOption) {
  return (
    <div class="flex items-center justify-start">
      <VIcon currency={option.assetName as string} class="mr-0.08 h-0.24 w-0.24" />
      {option.assetName}
    </div>
  )
}
</script>

<template>
  <v-modal
    v-model:show="modal.deposit"
    modal-class="w-4.48"
    title="Deposit"
  >
    <div class="mb-0.12 text-0.16 text-grey1">
      Select Token
    </div>
    <n-select
      v-model:value="current"
      :render-label="renderLabel"
      :options="currencyList.filter(i => i.canDepositFrontend)"
      filterable
      label-field="assetName" value-field="assetId"
      @update-value="handleChangeCurrency"
    />
    <div class="mb-0.12 mt-0.32 text-0.16 text-grey1">
      Amount
    </div>
    <v-input v-model="amount" align="left" :error-message="errorMsg" @input="errorMsg = ''">
      <template #suffix>
        <v-button type="outline" size="small" @click="toMaxAmount">
          MAX
        </v-button>
      </template>
    </v-input>
    <div class="mt-0.12 flex justify-end gap-x-0.04 text-0.12 font-600 text-grey1">
      <div>Available</div>
      <div class="flex gap-x-0.04 text-white2">
        <svg-loading v-if="isLoadingBalance" class="animate-spin animate-duration-2000" />
        <span v-else>{{ formatInt(balance) }}</span> {{ currentItem?.assetName }}
      </div>
    </div>
    <div v-if="!IS_MAINNET" class="mt-0.32 text-center text-0.12 font-600 text-grey1">
      Remember to visit our
      <span class="cursor-pointer text-primary" @click="toFaucet">Faucet page</span>
      daily to claim your test tokens and supercharge your trial trades, risk-free.
    </div>
    <template #footer>
      <div class="mt-0.32 text-center text-0.12 font-600 text-grey1">
        Your deposit is queued for processing on blockchain and should be completed within approximately 5 minutes.
      </div>
      <v-button
        class="mt-0.32 w-full"
        :loading="isDeposit"
        :disabled="+amount > +balance || !amount" @click="deposit"
      >
        {{ +amount > +approveBalance ? 'Enable' : 'Deposit' }}
      </v-button>
    </template>
  </v-modal>
</template>

<style scoped>
.wallet-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
