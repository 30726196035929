import VAULT_ABI from '~/abi/vault.json'
import ERC20_ABI from '~/abi/erc20.json'

export function addAccountChangedListener() {
  const { address } = useUser()
  const { web3 } = useConnector()
  const { assetBalance } = useWs()
  const { modal, closeModal } = useModal()
  if (!web3.value) {
    return
  }
  const onAccountChange = async (accounts: string[]) => {
    if (accounts.length && accounts[0] !== address.value) {
      location.reload()
      // assetBalance.value = []
      // await activateWallet()
      // if (modal.value.invitationCodeBound) {
      //   closeModal('invitationCodeBound')
      // }
    }
    else if (!accounts.length) {
      assetBalance.value = []
      disconnectWallet()
    }
  }
  web3.value.provider.removeListener('accountsChanged', onAccountChange)
  web3.value.provider.on('accountsChanged', onAccountChange)
}

export async function initConnectWallet() {
  const { web3 } = useConnector()
  const { closeModal } = useModal()
  const { address, isConnected } = useUser()
  if (!web3.value) {
    return
  }
  const accounts: any = await web3.value.provider.request({ method: 'eth_accounts' })
  if (accounts.length as string[]) {
    address.value = accounts[0]
    closeModal('chooseProvider')
    initNewWalletAddress()
    return true
  }
  return false
}

export async function requestAccount() {
  const { web3 } = useConnector()
  const { closeModal, openModal } = useModal()
  const { address, isConnected } = useUser()
  if (!web3.value) {
    return
  }
  closeModal('chooseProvider')
  openModal('connecting')
  try {
    const accounts: any = await web3.value.provider.request({ method: 'eth_requestAccounts' })
    if (!accounts) {
      console.log('Not connected')
      return
    }
    address.value = accounts[0] as string
    initNewWalletAddress()
    closeModal('connecting')
  }
  catch (e: any) {
    if (e.code === -32002) {
      globalMessage.error('You have already send a connect request in metamask, please check.')
    }
    openModal('chooseProvider')
    closeModal('connecting')
  }
}

export async function activateWallet() {
  const isConnected = await initConnectWallet()
  if (!isConnected) {
    await requestAccount()
  }
  checkAndBindVesselKey()
}

export async function getContract(contractAddress: string) {
  const { web3 } = useConnector()
  const { address } = useUser()
  if (!web3.value) {
    return
  }
  if (contractAddress === VAULT_ADDRESS) {
    return new web3.value.eth.Contract(VAULT_ABI, contractAddress, {
      from: address.value,
    })
  }
  else {
    return new web3.value.eth.Contract(ERC20_ABI, contractAddress, {
      from: address.value,
    })
  }
}

export async function getClaimableFromAssetName(assetName: string) {
  const { currencyList } = useSymbol()
  const { address } = useUser()
  const assetItem = currencyList.value.find(i => i.assetName === assetName)
  if (!assetItem?.address) {
    return 0
  }
  const contract = await getContract(VAULT_ADDRESS)
  const res = await (contract.methods.getPendingWithdraw as any)(address.value, assetItem.assetId).call()
  return +dividedByPower(res, assetItem.onChainDecimal) || 0
}
