<script setup lang="ts">
const { closeModal: close, modal } = useModal()
const { web3 } = useConnector()

function closeModal() {
  close('changeChainID')
}

async function changeChain() {
  if (!web3.value) {
    closeModal()
    return
  }
  const request = web3.value.provider.request
  const chainId = web3.value.utils.toHex(CHAIN_ID)

  try {
    // switch network
    await request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId }],
    })
    closeModal()
  }
  catch (err: any) {
    if (err.code === 4902) {
      try {
        // add network
        await request({
          method: 'wallet_addEthereumChain',
          params: [{
            chainId,
            chainName: CHAIN_NAME,
            rpcUrls: [CHAIN_RPC],
            nativeCurrency: {
              name: CURRENCY_NAME,
              symbol: CURRENCY_NAME,
              decimals: CURRENCY_DECIMALS,
            },
          }],
        })
        closeModal()
      }
      catch (addError) {
        console.error(addError)
      }
    }
    else {
      // message.error(`ERROR:${err.message}`);
    }
  }
}
</script>

<template>
  <v-modal
    v-model:show="modal.changeChainID"
    modal-class="w-5.5 text-grey1"
    :z-index="201"
    title="Wrong network"
  >
    <div>Please change your network to:</div>
    <div>Chain ID: {{ CHAIN_ID }}</div>
    <div>Chain Name: {{ CHAIN_NAME }}</div>
    <template #footer>
      <div class="mt-0.32 flex gap-x-0.16">
        <v-button type="outline" class="flex-1" @click="closeModal">
          Cancel
        </v-button>
        <v-button class="flex-1" @click="changeChain">
          Change
        </v-button>
      </div>
    </template>
  </v-modal>
</template>

<style scoped>
.wallet-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
