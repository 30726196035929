import axios from 'axios'

const instance = axios.create({
  baseURL: '/api/v1',
})

instance.interceptors.request.use((config) => {
  // console.log(config)
  const headers = getApiHeader(config)

  for (const key of Object.keys(headers)) {
    if (!config.headers[key]) {
      config.headers[key] = headers[key]
    }
  }
  return config
})

instance.interceptors.response.use((res) => {
  // console.log(config)
  if (res.data.error) {
    globalMessage.error(res.data.msg)
  }
  return res
}, (res) => {
  if (res.response.status === 401) {
    disconnectWallet()
    location.reload()
  }
})

export const http = instance
