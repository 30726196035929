import type { Router } from 'vue-router'

export function routeGuard(router: Router) {
  const { invitation } = useUser()
  router.beforeEach((to, from, next) => {
    const isValid = invitation.value.isValid

    if ((!isValid) && to.path !== '/login') {
      next('/login')
    }
    else
      if (to.path === '/faucet' && IS_MAINNET) {
        next('/trade')
      }
      else if (to.path === '/') {
        next('/trade')
      }
      else {
        next()
      }
  })
}
