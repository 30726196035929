import Web3 from 'web3'

export type WalletConnectType = 'metamask'

export class ProviderNotFoundError extends Error {
  name = 'ProviderNotFoundError'
  message = 'Provider not found'
}
export class ChainIdNotMatchError extends Error {
  name = 'ChainIdNotMatchError'
  message = 'Chain ID not match'
}
export class Web3NotFoundError extends Error {
  name = 'Web3NotFoundError'
  message = 'Web3 not found'
}

export const useConnector = createGlobalState(() => {
  const { openModal } = useModal()
  const web3 = ref<Web3>()
  const lastConnectWalletType = useStorage<WalletConnectType | ''>('walletType', '')

  async function connectProvider() {
    if (lastConnectWalletType.value) {
      const connect: Record<WalletConnectType, () => void> = {
        metamask: connectMetamask,
      }
      await connect[lastConnectWalletType.value]()
    }
  }

  async function getProvider() {
    if (typeof window !== 'undefined' && !!window.ethereum) {
      return window.ethereum
    }

    window.open(
      `https://metamask.app.link/dapp/${location.href}`,
      '_blank',
    )
  }

  async function checkChain() {
    if (!web3.value)
      throw new Web3NotFoundError()
    const chainId = await web3.value.eth.getChainId()
    if (chainId !== web3.value.utils.toBigInt(CHAIN_ID)) {
      openModal('changeChainID')
      return false
    }
    return true
  }

  async function disconnect() {
    web3.value = null
    lastConnectWalletType.value = ''
  }

  async function connectMetamask() {
    let provider = await getProvider()
    if (provider) {
      const isMulti = (provider?.providers?.length || 0) > 1
      isMulti
      && (provider
        = provider?.providers?.find((e: any) => e.isMetaMask)
        || provider)

      web3.value = new Web3(provider || Web3.givenProvider)
      await checkChain()
      addAccountChangedListener()
    }
    else {
      throw new ProviderNotFoundError()
    }
  }

  async function initWallet() {
    await connectProvider()
    initConnectWallet()
  }

  return {
    web3,
    connectProvider,
    lastConnectWalletType,
    initWallet,
    disconnect,
    checkChain,
  }
})
