import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router/auto'
import * as Sentry from '@sentry/vue'
import App from './App.vue'
import { useComponents } from '~/components'

import '@unocss/reset/tailwind.css'
import './styles/main.css'
import 'uno.css'

const app = createApp(App)
const env: string = import.meta.env.VITE_SENTRY_ENV
const sentryDsn: string = import.meta.env.VITE_SENTRY_DSN

Sentry.init({
  app,
  dsn: sentryDsn,
  environment: env,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
})
routeGuard(router)
app.use(router)
app.use(useComponents)
app.mount('#app')
