import BN from 'bignumber.js'

export async function disconnectWallet() {
  const { address, clearUserInfo, isConnected } = useUser()
  const { assetBalance } = useWs()
  const registeredAddress = useStorage('REGISTERED_ADDRESS', [])
  const connector = useConnector()
  const { clearKey } = useKey()
  const { currentOpenOrderList } = useOrder()
  const { initAirdrop } = useAirdrop()
  clearKey(address.value)
  isConnected.value = false
  connector.disconnect()
  clearUserInfo()
  initAirdrop()
  registeredAddress.value = registeredAddress.value.filter(i => i !== address.value)
  currentOpenOrderList.value = []
  assetBalance.value = []
  address.value = ''
}

export function initInfoAfterUser() {
  const { getFaucetInfo } = useFaucet()
  const { subscribeBalance } = useWs()
  const { getCurrentTabOrder } = useOrder()
  getFaucetInfo()
  subscribeBalance()
  getCurrentTabOrder()
}

export function initNewWalletAddress() {
  const { address, initUserInfo, isConnected, clearUserInfo, userInfo } = useUser()
  const { initKey, getVesselKey } = useKey()
  const { currentOpenOrderList } = useOrder()
  const { run: verifyAddress } = useHttp(vesselApi.stats.verifyAddress)
  const { invitation, initStatsUserInfo } = useUser()
  const { openModal } = useModal()

  clearUserInfo()
  isConnected.value = true
  const isSigned = initKey(address.value)
  if (isSigned) {
    initUserInfo().then(() => {
      initStatsUserInfo()
      currentOpenOrderList.value = userInfo.value.currentOpenOrders.reduce((res, i) => [...res, ...i.orders], [])
    })
    initInfoAfterUser()
  }
  else {
    checkAndBindVesselKey()
  }
}

export function checkAndBindVesselKey() {
  const { run: verifyAddress } = useHttp(vesselApi.stats.verifyAddress)
  const { address, invitation, initStatsUserInfo } = useUser()
  const { getVesselKey } = useKey()
  const { openModal } = useModal()
  const message = useMessage()

  verifyAddress({
    address: address.value,
  }).then((res) => {
    if (!res.data.isValid) {
      if (!invitation.value.code) {
        openModal('walletNotBound')
      }
      else {
        invitation.value.isNewCode = true
        getVesselKey()
      }
    }
    else {
      invitation.value.isNewCode = false
      invitation.value.isValid = true
      invitation.value.code = ''
      if (location.pathname === '/login') {
        location.href = '/trade'
      }
      getVesselKey()
    }
  })
}

export function getCurrencyFromSymbol(symbol: string) {
  const { symbolList } = useSymbol()
  const symbolItem = symbolList.value.find(i => i.symbol === symbol)
  return symbolItem
}

export function getPrecisionFromSymbol(symbol: string) {
  const { tickerInfo } = useSymbol()
  const tickerItem = tickerInfo.value.find(i => i.symbol === symbol)
  if (!tickerItem) {
    return {
      price: 2,
      total: 4,
      base: 2,
      value: 4,
      amount: 2,
    }
  }
  return {
    price: tickerItem.quoteAssetPrecision - tickerItem.baseAssetPrecision,
    total: tickerItem.quoteAssetPrecision,
    value: tickerItem.quoteAssetPrecision,
    quote: tickerItem.quoteAssetPrecision,
    amount: tickerItem.baseAssetPrecision,
    base: tickerItem.baseAssetPrecision,
  }
}

export function getUserFee(symbol: string) {
  const { userInfo } = useUser()

  const { tickerInfo } = useSymbol()
  if (!symbol || !userInfo.value) {
    return {
      takerFee: 0,
      makerFee: 0,
    }
  }
  const tickerItem = tickerInfo.value?.find(i => i.symbol === symbol)

  if (!tickerItem) {
    return {
      takerFee: userInfo.value.takerFeeRate,
      makerFee: userInfo.value.makerFeeRate,
    }
  }

  return {
    takerFee: +BN(userInfo.value.takerFeeRate).multipliedBy(tickerItem.takerFeeDiscount).dividedBy(BN(100)).toFixed(6),
    makerFee: +BN(userInfo.value.makerFeeRate).multipliedBy(tickerItem.makerFeeDiscount).dividedBy(BN(100)).toFixed(6),
  }
}
