import { Buffer } from 'buffer'
import { HDKey } from '@scure/bip32'
import { entropyToMnemonic, mnemonicToSeedSync } from '@scure/bip39'
import { wordlist } from '@scure/bip39/wordlists/english'
import { keccak256 } from 'ethereum-cryptography/keccak'
import secp256k1 from 'secp256k1'

export function stripHexPrefix(input: string): string {
  if (input.indexOf('0x') === 0) {
    return input.slice(2)
  }

  return input
}

export function exportMnemonicAndPrivateKey(entropy: Uint8Array, path = 'm/44\'/118\'/0\'/0/0'): {
  mnemonic: string
  privateKey: string | null
  publicKey: string | null
} {
  const mnemonic = entropyToMnemonic(entropy, wordlist)
  const seed = mnemonicToSeedSync(mnemonic)

  const hdkey = HDKey.fromMasterSeed(seed)
  const derivedHdkey = hdkey.derive(path)

  if (!hdkey.privateKey) {
    throw new Error('null hd key')
  }
  const privateKey = byteToHex(derivedHdkey.privateKey)

  const publicKey = secp256k1.publicKeyCreate(hexToByte(privateKey), false)

  return {
    mnemonic,
    privateKey,
    publicKey: byteToHex(publicKey),
  }
}

/**
 * @description Get private information for onboarding using an Ethereum Signature.
 *
 * @returns Mnemonic and Public/Private HD keys
 */
export function deriveHDKeyFromEthereumSignature(signature: string): {
  mnemonic: string
  privateKey: string | null
  publicKey: string | null
} {
  const buffer = Buffer.from(stripHexPrefix(signature), 'hex')

  if (buffer.length !== 65) {
    throw new Error('Signature must be 65 bytes')
  }

  // Remove the 'v' value by taking only the first 64 bytes of the signature
  const rsValues = buffer.subarray(0, 64)
  // Hash the 'r' and 's' values down to 32 bytes (256 bits) using Keccak-256
  const entropy = keccak256(rsValues)
  return exportMnemonicAndPrivateKey(entropy)
}

export async function signToGetVesselKey() {
  const { address } = useUser()
  const { web3 } = useConnector()
  if (!address.value || !web3.value) {
    throw new Error('Error get key')
  }
  const msgParams = JSON.stringify({
    domain: {
      chainId: CHAIN_ID,
      name: 'Vessel',
    },
    message: {
      nonce: '1',
      origin: location.origin,
      action: 'Vessel Key',
      onlySignOn: SIGN_URL,
    },
    primaryType: 'Vessel',
    types: {
      EIP712Domain: [
        { name: 'name', type: 'string' },
        { name: 'chainId', type: 'uint256' },
      ],
      Vessel: [
        { name: 'action', type: 'string' },
        { name: 'onlySignOn', type: 'string' },
        { name: 'nonce', type: 'string' },
      ],
    },
  })

  const params = [address.value, msgParams]
  const method = 'eth_signTypedData_v4'

  try {
    const signature: any = await web3.value.provider.request({
      method,
      params,
    })

    const { privateKey, publicKey } = deriveHDKeyFromEthereumSignature(signature)
    return {
      publicKey: `0x${publicKey?.slice(4)}` || '',
      privateKey: privateKey || '',
    }
  }
  catch (e) {
    throw new Error('Error get key')
  }
}

export async function SignToGetApiKey(vesselKey: string) {
  const { address } = useUser()
  const { web3 } = useConnector()
  if (!address.value || !web3.value) {
    throw new Error('Error get key')
  }
  const msgParams = JSON.stringify({
    domain: {
      chainId: CHAIN_ID,
      name: 'Vessel',
    },
    message: {
      address: address.value,
      vesselKey,
      action: 'API Key',
      onlySignOn: SIGN_URL,
      nonce: '1',
    },
    primaryType: 'Vessel',
    types: {
      EIP712Domain: [
        { name: 'name', type: 'string' },
        { name: 'chainId', type: 'uint256' },
      ],
      Vessel: [
        { name: 'address', type: 'address' },
        { name: 'vesselKey', type: 'string' },
        { name: 'action', type: 'string' },
        { name: 'onlySignOn', type: 'string' },
        { name: 'nonce', type: 'string' },
      ],
    },
  })

  const params = [address.value, msgParams]
  const method = 'eth_signTypedData_v4'

  try {
    const signature: any = await web3.value.eth.provider.request({
      method,
      params,
    })
    return {
      signature,
      msgParams,
    }
  }
  catch (e) {
    throw new Error('Error get key')
  }
}
