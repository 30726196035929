<script setup lang="tsx">
import { useAsyncValidator } from '@vueuse/integrations/useAsyncValidator'
import { NIcon } from 'naive-ui'
import type { SelectGroupOption, SelectOption, UploadFileInfo } from 'naive-ui'

const { closeModal: close, modal, feedbackType } = useModal()
const { address } = useUser()
const { web3 } = useConnector()

const isFeedback = computed(() => feedbackType.value === 'feedback')
const { run: submitFeedback, isLoading: loadingFeedback } = useHttp(vesselApi.stats.feedback)
const { run: submitBug, isLoading: loadingBug } = useHttp(vesselApi.stats.reportBug)
const message = useMessage()
const form = ref({
  reachType: 'Email',
  email: '',
  address: '',
  detail: '',
  files: [],
})
const isValidate = ref(false)

const typeList: Array<SelectOption | SelectGroupOption> = [
  {
    label: 'Feedback',
    value: 'feedback',
  },
  {
    label: 'Bug',
    value: 'bug',
  },
]

function renderType(option: SelectOption) {
  return option.value === 'feedback'
    ? (
      <div class="flex items-center gap-x-0.12">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16" fill="none">
          <path d="M1.33398 14.6677V2.66768C1.33398 2.30102 1.46465 1.98724 1.72598 1.72635C1.98732 1.46546 2.3011 1.3348 2.66732 1.33435H13.334C13.7007 1.33435 14.0147 1.46502 14.276 1.72635C14.5373 1.98768 14.6678 2.30146 14.6673 2.66768V10.6677C14.6673 11.0344 14.5369 11.3484 14.276 11.6097C14.0151 11.871 13.7011 12.0015 13.334 12.001H4.00065L1.33398 14.6677ZM8.00065 10.001C8.18954 10.001 8.34798 9.93702 8.47598 9.80902C8.60398 9.68102 8.66776 9.52279 8.66732 9.33435C8.66732 9.14546 8.60332 8.98724 8.47532 8.85968C8.34732 8.73213 8.1891 8.66813 8.00065 8.66768C7.81176 8.66768 7.65354 8.73168 7.52598 8.85968C7.39843 8.98768 7.33443 9.14591 7.33398 9.33435C7.33398 9.52324 7.39798 9.68168 7.52598 9.80968C7.65398 9.93768 7.81221 10.0015 8.00065 10.001ZM7.33398 7.33435H8.66732V3.33435H7.33398V7.33435Z" fill="#FCFCFD" />
        </svg>
        Feedback
      </div>
      )
    : (
      <div class="flex items-center gap-x-0.12">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M12 21C10.9167 21 9.91267 20.7333 8.988 20.2C8.06333 19.6667 7.334 18.9333 6.8 18H4V16H6.1C6.05 15.6667 6.02067 15.3333 6.012 15C6.00333 14.6667 5.99933 14.3333 6 14H4V12H6C6 11.6667 6.004 11.3333 6.012 11C6.02 10.6667 6.04933 10.3333 6.1 10H4V8H6.8C7.03333 7.61667 7.296 7.25833 7.588 6.925C7.88 6.59167 8.21733 6.3 8.6 6.05L7 4.4L8.4 3L10.55 5.15C11.0167 5 11.4917 4.925 11.975 4.925C12.4583 4.925 12.9333 5 13.4 5.15L15.6 3L17 4.4L15.35 6.05C15.7333 6.3 16.0793 6.58733 16.388 6.912C16.6967 7.23667 16.9673 7.59933 17.2 8H20V10H17.9C17.95 10.3333 17.9793 10.6667 17.988 11C17.9967 11.3333 18.0007 11.6667 18 12H20V14H18C18 14.3333 17.9957 14.6667 17.987 15C17.9783 15.3333 17.9493 15.6667 17.9 16H20V18H17.2C16.6667 18.9333 15.9377 19.6667 15.013 20.2C14.0883 20.7333 13.084 21 12 21ZM10 16H14V14H10V16ZM10 12H14V10H10V12Z" fill="#FCFCFD" />
        </svg>
        Bug
      </div>
      )
}

const { pass, isFinished, errorFields, execute: validateFields } = useAsyncValidator(form, {
  email: [
    {
      required: true,
      message: 'Please enter your contact information.',
    },
    {
      validator(rule, value, cb) {
        if (form.value.reachType === 'Email' && !/^\w+([-.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value)) {
          cb('Invalid Email Format')
        }
        cb()
      },
    },
  ],
  detail: [
    {
      required: true,
      message: () => isFeedback.value ? 'Please enter your feedback.' : 'Please enter your description.',
    },
    {
      max: 500,
      message: 'Length must be less than 500',
    },
  ],
}, {
  manual: true,
})

function getErrorMessage(field: string) {
  return isValidate.value ? errorFields.value[field]?.[0]?.message : ''
}

const reachTypeList = ['Email', 'Telegram', 'Discord', 'Twitter']

function changeFeedbackType() {
  if (feedbackType.value === 'feedback') {
    feedbackType.value = 'bug'
  }
  else {
    feedbackType.value = 'feedback'
  }
}

function onChangeFile(fileList: UploadFileInfo[]) {
  form.value.files = fileList
}

function initModal() {
  form.value.address = address.value
}

function closeModal() {
  close('feedback')
  isValidate.value = false
}

function resetForm() {
  form.value = {
    reachType: 'Email',
    email: '',
    address: '',
    detail: '',
    files: [],
  }
}

async function submit() {
  isValidate.value = true

  await validateFields()
  if (!pass.value || loadingFeedback.value || loadingBug.value) {
    return
  }
  const formData = new FormData()
  switch (form.value.reachType) {
    case 'Email':
      formData.append('userEmail', form.value.email)
      break
    case 'Telegram':
      formData.append('telegramId', form.value.email)
      break
    case 'Discord':
      formData.append('discordId', form.value.email)
      break
    case 'Twitter':
      formData.append('twitterId', form.value.email)
      break
  }

  formData.append('feedbackDetail', form.value.detail)
  formData.append('walletAddress', form.value.address)
  for (const file of form.value.files) {
    formData.append('files', file.file)
  }
  if (isFeedback.value) {
    submitFeedback(formData).then((res) => {
      if (!res.data.error) {
        message.success('Your feedback has been submitted. We appreciate your insights!')
        closeModal()
        resetForm()
      }
      else {
        message.error(res.data.message)
      }
    })
  }
  else {
    submitBug(formData).then((res) => {
      if (!res.data.error) {
        message.success('Bug report submitted! Thank you for the report. We will investigate after confirmation.')
        closeModal()
        resetForm()
      }
      else {
        message.error(res.data.message)
      }
    })
  }
}
</script>

<template>
  <v-modal
    v-model:show="modal.feedback"
    modal-class="w-5.5 text-grey1"
    :z-index="201"
    :title="isFeedback ? 'Feedback' : 'Report a Bug'"
    @open="initModal" @close="closeModal"
  >
    <div class="no-scroll h-4.5 flex flex-col gap-y-0.32 overflow-auto">
      <div>
        <div class="mb-0.12 text-0.16 text-grey2">
          Type
        </div>
        <div class="flex items-center justify-between text-white2">
          <!-- <div v-if="isFeedback" class="flex items-center gap-x-0.12">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16" fill="none">
              <path d="M1.33398 14.6677V2.66768C1.33398 2.30102 1.46465 1.98724 1.72598 1.72635C1.98732 1.46546 2.3011 1.3348 2.66732 1.33435H13.334C13.7007 1.33435 14.0147 1.46502 14.276 1.72635C14.5373 1.98768 14.6678 2.30146 14.6673 2.66768V10.6677C14.6673 11.0344 14.5369 11.3484 14.276 11.6097C14.0151 11.871 13.7011 12.0015 13.334 12.001H4.00065L1.33398 14.6677ZM8.00065 10.001C8.18954 10.001 8.34798 9.93702 8.47598 9.80902C8.60398 9.68102 8.66776 9.52279 8.66732 9.33435C8.66732 9.14546 8.60332 8.98724 8.47532 8.85968C8.34732 8.73213 8.1891 8.66813 8.00065 8.66768C7.81176 8.66768 7.65354 8.73168 7.52598 8.85968C7.39843 8.98768 7.33443 9.14591 7.33398 9.33435C7.33398 9.52324 7.39798 9.68168 7.52598 9.80968C7.65398 9.93768 7.81221 10.0015 8.00065 10.001ZM7.33398 7.33435H8.66732V3.33435H7.33398V7.33435Z" fill="#FCFCFD" />
            </svg>
            Feedback
          </div>
          <div v-else class="flex items-center gap-x-0.12">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M12 21C10.9167 21 9.91267 20.7333 8.988 20.2C8.06333 19.6667 7.334 18.9333 6.8 18H4V16H6.1C6.05 15.6667 6.02067 15.3333 6.012 15C6.00333 14.6667 5.99933 14.3333 6 14H4V12H6C6 11.6667 6.004 11.3333 6.012 11C6.02 10.6667 6.04933 10.3333 6.1 10H4V8H6.8C7.03333 7.61667 7.296 7.25833 7.588 6.925C7.88 6.59167 8.21733 6.3 8.6 6.05L7 4.4L8.4 3L10.55 5.15C11.0167 5 11.4917 4.925 11.975 4.925C12.4583 4.925 12.9333 5 13.4 5.15L15.6 3L17 4.4L15.35 6.05C15.7333 6.3 16.0793 6.58733 16.388 6.912C16.6967 7.23667 16.9673 7.59933 17.2 8H20V10H17.9C17.95 10.3333 17.9793 10.6667 17.988 11C17.9967 11.3333 18.0007 11.6667 18 12H20V14H18C18 14.3333 17.9957 14.6667 17.987 15C17.9783 15.3333 17.9493 15.6667 17.9 16H20V18H17.2C16.6667 18.9333 15.9377 19.6667 15.013 20.2C14.0883 20.7333 13.084 21 12 21ZM10 16H14V14H10V16ZM10 12H14V10H10V12Z" fill="#FCFCFD" />
            </svg>
            Bug
          </div>
          <div class="cursor-pointer text-0.12 text-primary" @click="changeFeedbackType">
            ⇌ Switch to <span v-if="isFeedback">Report a Bug</span> <span v-else>Feedback</span>
          </div> -->
          <n-select v-model:value="feedbackType" :options="typeList" :render-label="renderType" />
        </div>
      </div>
      <div>
        <div class="mb-0.12 text-0.16 text-grey2">
          How can we reach out to you? <span class="ml-0.05 text-primary">*</span>
        </div>
        <div class="my-0.12 flex items-center justify-between text-white2">
          <v-radio-group v-model="form.reachType" :options="reachTypeList" />
        </div>
        <v-input v-model="form.email" align="left" :class="!!getErrorMessage('email') ? 'error' : ''" @click="isValidate = false" />
        <div class="mt-0.04 text-0.12 text-red">
          {{ getErrorMessage('email') }}
        </div>
      </div>
      <div>
        <div class="mb-0.12 text-0.16 text-grey2">
          Wallet Address
        </div>
        <v-input v-model="form.address" align="left" />
      </div>
      <div class="relative">
        <div class="mb-0.12 text-0.16 text-grey2">
          {{ isFeedback ? 'Share Your Feedback' : 'Describe the Issue' }}
        </div>
        <textarea
          v-model="form.detail"
          :rows="4" :placeholder="isFeedback ? 'Tell us what you think or suggest to improve.' : 'Please provide details to the bug in description.'" class="detail w-full resize-none bg-transparent px-0.16 py-0.12 text-0.14 font-500 text-white2 outline-none"
          :class="!!getErrorMessage('detail') ? 'error' : ''" @click="isValidate = false"
        />
        <div class="absolute right-0.1 top-1.2 text-0.14">
          {{ form.detail.length }}/500
        </div>
        <div class="text-0.12 text-red">
          {{ getErrorMessage('detail') }}
        </div>
      </div>
      <div>
        <div class="mb-0.12 text-0.16 text-grey2">
          Attach Screenshots (Up to 3 files, 5MB each.)
        </div>
        <n-upload
          action="https://www.mocky.io/v2/5e4bafc63100007100d8b70f"
          list-type="image-card" :on-update:file-list="onChangeFile"
          :max="3" accept="image/png, image/jpeg"
        />
      </div>
    </div>
    <template #footer>
      <div class="mt-0.32 flex gap-x-0.16">
        <v-button type="outline" class="flex-1" @click="closeModal">
          Cancel
        </v-button>
        <v-button class="flex-1" :loading="loadingFeedback || loadingBug" @click="submit">
          {{ isFeedback ? 'Submit' : 'Report Bug' }}
        </v-button>
      </div>
    </template>
  </v-modal>
</template>

<style scoped lang="scss">
.wallet-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.detail {
  border: 2px solid var(--vessel-color-black-3);
  border-radius: calc(12rem / 400);
  &:not(.disabled) {
    &:hover {
      border: 2px solid var(--vessel-color-grey-1);
    }
    &:focus-within, &:active {
      border: 2px solid var(--vessel-color-primary);
    }
  }
  &.error {
    border: 2px solid var(--vessel-color-red) !important;
  }
}
</style>
